body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  /* background-color: #4d5a67; */
  background-color: #fbfbfb;
  min-height: 100vh;
}
.hero-body {
  padding-top: 1em !important;
}
.container {
  padding-bottom: 50px;
  max-width: 100vw !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#markdown {
  padding-right: 0 !important;
  background-color: white;
  min-height: 100vh;
}
.side-column {
  flex: 1 !important;
  padding: 1rem !important;
}
#header {
  padding: 1.75rem 2rem 1rem 2rem;
  /* border-bottom: 0.5px dashed #888; */
  background-color: #6e7798;
}
textarea {
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  /* color: white; */
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
textarea:focus {
  border: none;
  outline: none;
}
ul {
  list-style: circle !important;
}
ul,
ol {
  padding-left: 35px !important;
}
#button-holder {
  display: flex;
  align-items: center;
}
/* strong {
  color: white !important;
}
a {
  color: lightblue !important;
}
a:hover {
  color: white !important;
} */
#preview p {
  margin: 0.6em 0;
}
#preview p:first-child {
  margin-top: 0;
}
#preview {
  background-color: #f2f2f2;
  padding: 1rem;
}
#modal > div {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
#modal .content {
  margin: auto;
}
.message {
  position: absolute;
  top: 30px;
  right: 10px;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
button.is-small:not(:last-child) {
  margin-right: 16px;
}

@media screen and (max-width: 768px) {
  #markdown {
    border-right: none;
  }
  #button-holder {
    justify-content: center;
  }
  #preview {
    padding: 1rem 2rem !important;
  }
  textarea {
    min-height: 100vh;
  }
}
.loading-spinner {
  animation-play-state: running;
  opacity: 1;
  position: relative;
  height: 50px;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.loading-spinner.-paused {
  animation-play-state: paused;
  opacity: 0.2;
  transition: opacity linear 0.2s;
}

.loading-spinner::before {
  animation: 0.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 1.5px #dedede;
  border-bottom-color: #414159;
  border-radius: 50%;
  content: "";
  height: 30px;
  left: 50%;
  opacity: inherit;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 30px;
  will-change: transform;
}
.has-addons button {
  font-size: 0.8em;
}
.has-no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
hr {
  background-color: gray !important;
}
